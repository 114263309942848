<!--
 * @Descripttion:术语和定义
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:18:26
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-21 17:50:36
-->
<template>
    <div class="TermAndDefinition">
      <div class="TermAndDefinitionHeader">
         <Form ref="formInline" label-position="right" :label-width="100" inline>
            <FormItem prop="term" label="术语和定义">
               <Input type="text" v-model="term" placeholder="请输入术语和定义" />
            </FormItem>
            <QueryParam ref="QueryParam" />
            <FormItem>
               <Button type="primary" class="leftBtn" @click="query">查询</Button>
               <Button  @click="handleReset" style="margin-left:8px;">重置</Button>
            </FormItem>
         </Form>
      </div>
      <div class="TermAndDefinitionContent">  
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
      <statisticsModal ref="statisticsModal" />
      <PdfModal ref="PdfModal" />
      <ModifyModal ref="ModifyModal" />
    </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import QueryParam from '@/components/QueryParam';
import TermAndDefinition from '@/api/TermAndDefinition';
import statisticsModal from '@/components/statisticsModal';
import PdfModal from '@/components/PdfModal';
import ModifyModal from '@/components/ModifyModal';
export default {
   name:"TermAndDefinition",
    data(){
      return {
         "term":"",
         "columns":[
            {"title":"序号","key":"indexNo","width":70},
            {"title":"中文","key":"technicalTermName","width":220},
            {"title":"英文","key":"technicalTermEngName","width":220},
            {"title":"解释","key":"technicalTermDef"},
            {"title":"条款号","key":"standSn","width":110},
            {"title":"标准号","key":"standNo",
             "width":220,
               "render":(h, params) => {
                  var value = params["row"]["standNo"];
                  let name =  params["row"]["standName"];
                  return h('Tooltip',{props:{placement: 'top'}}, [
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["statisticsModal"].open({
                                    "standNo":value,
                                     "modalName":value+"　　　"+name
                                 });
                              }
                           }
                        }, value),
                         h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准详细数据")
                     ]);
               }
            },
            {"title":"标准名称","key":"standName",
               "width":300,
               "render":(h, params) => {
                  let value = params["row"]["standName"];
                  let fjUrl = params["row"]["fjUrl"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["PdfModal"].open(fjUrl);
                              }
                           }
                        }, value),
                      h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准原文")   
                     ]);
               }
            },
            {"title":"提出修订意见",
               "width":150,
               "render":(h, params) => {
                   let {table,tableId,standNo,standName,technicalTermDef} = params['row'];
                  return h('div', [
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["ModifyModal"].open({
                                     "table":table,
                                     "tableId":tableId,
                                     "standNo":standNo,
                                     "standName":standName,
                                     "otherInfo":technicalTermDef
                                  });
                              }
                           }
                        }, '提出修订意见')
                     ]);
               }
            }
         ]
      }
   },
   mounted(){
      
   },
   activated(){
      // 获取查询参数;
      if(this.$route.params.standName){
         let {standName} = this.$route.params;
         this.$refs["QueryParam"].setParam({standName})
      }
      if(this.$route.params.standNo){
        let {standNo} = this.$route.params;
        this.$refs["QueryParam"].setParam({standNo})
      }
      if(this.$route.params.query && this.$route.params.query=="1"){
         this.query();
      }else{
         this.handleReset();
          this.$refs["QueryResult"].query(TermAndDefinition.query,{
             "standNo":"aaa",
             "standName":"",
             "standClass":"-1",
             "term":""
          },true);
      }
   },
   components:{
      "QueryResult":QueryResult,
      "QueryParam":QueryParam,
      "statisticsModal":statisticsModal,
      "PdfModal":PdfModal,
      "ModifyModal":ModifyModal
   },
   methods:{
      query(){
         let queryParam = this.$refs["QueryParam"].getParam();
         let term = this.term;
         if(this.term == "" && queryParam["standNo"]=="" && queryParam["standName"]==""){
            this.$Message.error('请输入关键字进行查询');
         }else{
               this.$refs["QueryResult"].query(TermAndDefinition.query,{term,...queryParam},true);
         }
      },
      handleReset(){
         this.$set(this,'term',"");
         this.$refs["QueryParam"].reset();
      }
   }
}
</script>
<style lang="less">
.TermAndDefinition{
   .TermAndDefinitionHeader{
      height:65px;
      .ivu-form-item{
         float:left;
      }
      .last.ivu-form-item{
         margin-left:85px;
         margin-right:0px;
      }
   }
}
</style>